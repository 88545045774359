@media (max-width: 768px) {
  .MuiPopover-paper {
    left: 30px !important;
    right: 0;
    max-width: calc(100% - 60px) !important;
  }
  div.project-info-desc {
    width: calc(100% - 16px);
  }
  .user-profile img {
    width: 50% !important;
  }
  .calendar-container .fc-header-toolbar {
    flex-wrap: wrap;
  }
  .calendar-container .fc-header-toolbar .fc-toolbar-chunk {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    margin-top: 24px;
  }
  div.filter-installer {
    flex-basis: 90% !important;
  }
  table .MuiTableCell-footer .MuiTablePagination-root {
    overflow: auto;
  }
  div.inventory-datatable .p-datatable-wrapper {
    height: auto !important;
    min-height: 200px;
  }
}

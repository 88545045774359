.route-optimazation
  .p-tabview
  .p-tabview-nav
  li
  .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: inset 0 0 0 0.2rem transparent;
}
.route-optimazation
  .p-tabview
  .p-tabview-nav
  li.p-highlight
  .p-tabview-nav-link {
  border-color: transparent;
}
.route-optimazation .p-tabview-panels {
  padding: 10px 0px;
}
.route-optimazation .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  margin: 0;
}
.p-calendar {
  width: 100%;
}
.p-tabview-panels {
  padding: 0 !important;
}

.installer-status.p-tag span {
  display: flex;
  align-items: baseline;
}

.pf-ui-dialog-fullscreen {
  transition: none;
  transform: none;
  margin: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  max-height: 100vh !important;
  top: 0 !important;
  left: 0 !important;
  border-radius: 0px !important;
}

.pf-button {
  margin: 0;
  display: inline-flex;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  vertical-align: bottom;
  text-align: center;
  overflow: hidden;
  position: relative;
  border-radius: 0 !important;
  width: 100% !important;
}

.pf-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #e9ecef;
  color: #0b0b0c !important;
  padding: 1.5rem;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.pf-inputtext {
  border-radius: 0px !important;
  width: 100% !important;
}

.pf-dropdown {
  border-radius: 0px !important;
  width: 100% !important;
}

.pf-paginator .p-dropdown {
  width: auto !important;
}

.pf-inputwrapper {
  width: 100% !important;
}

.pf-card-b {
  border: 1px solid #e9ecef;
}

.pf-footer-btn-div {
  border-bottom: 0 none;
  background: #e9ecef;
  color: #4338ca !important;
  padding: 1.5rem;
}

.pf-inline-message.p-inline-message-info {
  border-radius: 0px !important;
}

.pf-c-btn-width {
  width: 100px !important;
}

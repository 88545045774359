#labor-container {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto 0 !important;
  font-family: 'inter';
  page-break-inside: avoid;
  padding: 0px;
}
.labor-container .header {
  background: #eff6ff;
  padding: 20px;
}
.labor-container .text-blue {
  color: #004e93 !important;
}
.labor-container .text-grey {
  color: #64748b;
}
.labor-container .text-dark-800 {
  color: #495057;
}

.labor-container .bg-light-blue {
  background-color: #eff6ff;
}
.labor-container .table thead {
  background-color: #eff6ff;
}
.labor-container .table thead th {
  color: #0062b8;
  font-family: 'inter';
}
.labor-container table tr td,
.labor-container table tr tr {
  vertical-align: top;
  padding: 5px 2px;
}
.labor-container .labor-table {
  padding: 10px;
  font-size: 12px;
  border: 1px solid #dfe7ef;
  border-collapse: collapse;
}
.labor-container .pi-container {
  background-color: #eff6ff;
  padding: 5px;
  border-radius: 50px;
  color: #0074d9;
  margin-right: 5px;
}
.labor-container .text-dark-700 {
  color: #37404c;
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  body * {
    visibility: hidden;
    print-color-adjust: exact;
  }
  #labor-container * {
    visibility: visible;
  }
  #labor-container {
    position: absolute;
    left: 0;
    top: 0;
  }
  .labor-container .header {
    background-color: #eff6ff;
    print-color-adjust: exact;
  }
  .word_break {
    word-wrap: break-word;
    word-break: break-all;
  }
}

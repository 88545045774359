.loader-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  background: rgba(255, 255, 255, 0.7); /* semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* ensure loader is on top of other content */
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Apply animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.parent-highlight {
  background: #f8f8fa;
}

.current-highlight {
  background: #d0e1fd;
}

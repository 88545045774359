.p-tabview .p-tabview-panels {
  padding: 0.5rem;
  padding-bottom: 0.1rem;
}

.p-sidebar .p-sidebar-content {
  padding-bottom: 0;
}

.button {
  padding: 3px !important;
  text-align: center;
  display: flex;
  justify-content: center;
}

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 999;
  border: 1px solid #fff;
  color: #1f326d;
  border: 1px solid #ccc;
}

.autocomplete-dropdown-container > * {
  line-height: 1.5em;
  padding: 12px;
}

.p-autocomplete-input {
  width: 100%;
}

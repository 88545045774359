.virtual-scrolling.e-schedule .template-wrap .subject {
  padding: 10px 25px;
}

.virtual-scrolling.e-schedule .e-month-view .template-wrap .subject {
  padding: 3px 10px;
}

.virtual-scrolling.e-schedule .e-resource-left-td {
  width: 300px !important;
}
.e-schedule .e-timeline-month-view .e-appointment .e-appointment-details {
  padding: 0px;
}
.e-appointment-details > div:first-child {
  width: 100%;
}
.striped-event {
  background: repeating-linear-gradient(
    135deg,
    #dbdbdb 10px,
    #dbdbdb 12px,
    transparent 12px,
    transparent 20px
  ) !important;
}

.virtual-scrolling.e-schedule
  .e-timeline-month-view
  .e-date-header-wrap
  table
  col,
.virtual-scrolling.e-schedule .e-timeline-month-view .e-content-wrap table col {
  width: 200px;
}
.virtual-scrolling.e-schedule .e-more-popup-wrapper .e-appointment {
  background: none !important;
  height: 96px;
}
.e-more-popup-wrapper .e-appointment > div:first-child {
  width: 100%;
}
.e-appointment-details {
  padding: 0 !important;
}
.e-appointment-details > div:nth-child(2) {
  width: 100%;
}
.virtual-scrolling .e-appointment-details .e-indicator.e-left-icon,
.virtual-scrolling .e-appointment-details .e-indicator.e-right-icon,
.virtual-scrolling .e-appointment-details .e-recurrence-icon,
.virtual-scrolling .e-vertical-view .e-day-wrapper .e-appointment .e-up-icon {
  display: none;
}
.e-toolbar .e-toolbar-item .e-tbar-btn:hover {
  background-color: #0074d9;
  border-radius: 6px;
}
#refreshIconScheduler,
#fullscreenIconScheduler,
#exitFullscreenIconScheduler {
  display: none;
}
.md-refresh:hover,
.md-fullscreen:hover,
.md-fullscreen_exit:hover {
  box-shadow: 0px 0px 10px 0px;
  border-radius: 100%;
}
.virtual-scrolling.e-schedule .e-timeline-view .e-auto-height {
  height: auto;
}

.virtual-scrolling.e-schedule
  .e-vertical-view.e-timescale-disable
  .e-appointment {
  background: none !important;
  height: 100px;
}
.virtual-scrolling.e-schedule .e-month-view .e-appointment {
  background: none !important;
  height: 100px;
}
.virtual-scrolling.e-schedule .e-timeline-month-view .e-appointment {
  background: none !important;
  height: 100px;
}
.virtual-scrolling.e-schedule .e-timeline-view .e-appointment {
  background: none !important;
  height: 100px;
}
.virtual-scrolling.e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
  background: none !important;
  min-width: 40px !important;
}

/* workcell */
.virtual-scrolling.e-schedule .e-month-view .e-work-cells {
  height: 134px;
}
.virtual-scrolling.e-schedule .e-timeline-month-view .e-work-cells {
  height: 134px;
}
.virtual-scrolling.e-schedule .e-timeline-view .e-work-cells {
  height: 134px;
}

/*  */

/* Scheduler Tab CSS*/
.calendar-full-height .e-day-view .e-appointment-details,
.calendar-page-height .e-day-view .e-appointment-details {
  height: 100%;
}
.calendar-full-height .e-day-view .e-appointment-details > div:first-child,
.calendar-page-height .e-day-view .e-appointment-details > div:first-child {
  width: 100%;
  height: 100%;
}
.calendar-full-height .e-day-view .e-appointment-details .schedule-job-card,
.calendar-page-height .e-day-view.e-appointment-details .schedule-job-card {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

/* Calendar Toolbar CSS */
.virtual-scrolling.e-schedule .e-schedule-toolbar .e-toolbar-items.e-tbar-pos {
  height: 48px;
  min-height: 48px;
}
.virtual-scrolling .e-toolbar .e-toolbar-item .e-tbar-btn {
  min-height: 38px;
  height: 38px;
}
.virtual-scrolling .e-tbar-btn-text {
  font-weight: 600;
}
.virtual-scrolling .e-toolbar-item.e-views .e-tbar-btn:hover .e-tbar-btn-text {
  color: var(--black);
}
.virtual-scrolling .e-toolbar-item .e-views {
  background: #cadded;
}
.virtual-scrolling .e-toolbar-item.e-views.e-active-view .e-tbar-btn {
  background-color: var(--primary-color);
}
.virtual-scrolling .e-toolbar-item.e-timeline-day button,
.e-toolbar-item.e-day button {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}
.virtual-scrolling .e-toolbar-item.e-timeline-month button,
.e-toolbar-item.e-month button {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.virtual-scrolling
  .e-toolbar-item.e-views.e-active-view
  .e-tbar-btn
  .e-tbar-btn-text {
  color: var(--pf-white);
}
.virtual-scrolling .e-toolbar-item.e-views {
  padding: 0 !important;
}
.virtual-scrolling .e-toolbar-item.e-views .e-tbar-btn {
  /* background: #ebf6ff; */
  background: #daecfb;
  width: 70px !important;
}
/* border-radius: 6px !important; */

.virtual-scrolling .e-toolbar .e-toolbar-item .e-tbar-btn:focus,
.virtual-scrolling .md-refresh:hover,
.virtual-scrolling .md-fullscreen:hover,
.virtual-scrolling .fullscreenIconScheduler:hover {
  box-shadow: none;
}
.virtual-scrolling .e-today button {
  width: 60px !important;
  border-radius: 6px !important;
}
.virtual-scrolling .md-refresh:after,
.virtual-scrolling .md-fullscreen:after,
.virtual-scrolling .md-fullscreen_exit:after {
  color: var(--primary-color);
  border: 1px solid #dfdfdf;
  padding: 5px;
  border-radius: 5px;
}
.virtual-scrolling .e-toolbar .e-toolbar-item.e-separator {
  margin: 5px 20px 5px 5px;
}
.refreshIconScheduler {
  margin-left: 8px;
}

/* Height FUll screen */
.scheduler-page-height .scheduler-calendar-inner .e-control.e-schedule {
  height: calc(100vh - 335px) !important;
  min-height: 650px;
}
.scheduler-full-height .scheduler-calendar-inner .e-control.e-schedule {
  height: calc(100vh - 900px) !important;
  min-height: 650px;
}
.scheduler-calendar-parent {
  width: 100vw !important;
  height: 100vh !important;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1100;
}
/* calender view height */

.calendar-page-height .scheduler-calendar-inner .e-control.e-schedule {
  height: calc(100vh - 335px) !important;
  min-height: 650px;
}
.calendar-full-height .scheduler-calendar-inner .e-control.e-schedule {
  height: calc(100vh - 900px) !important;
  min-height: 650px;
}
.calendar-fullScreen .e-control.e-schedule {
  height: calc(100vh - 200px) !important;
  min-height: 650px;
}
/* scheduler-full-height */
.e-drag-clone {
  height: 98px !important;
}
.e-drag-clone .scheduler-event-parent {
  height: 100px;
}
.e-drag-clone .scheduler-event-parent {
  background-color: #f5f5f5 !important;
  height: 100px !important;
}
.calendar-full-height .calendar-view-fullscreen .e-day-view .e-content-wrap,
.calendar-full-height .calendar-view-fullscreen .e-day-view .e-time-cells-wrap,
.calendar-page-height .calendar-view-fullscreen .e-day-view .e-content-wrap,
.calendar-page-height .calendar-view-fullscreen .e-day-view .e-time-cells-wrap {
  height: calc(100vh - 112px) !important;
}
.word-break {
  word-wrap: break-word;
}
.resource-card {
  min-height: 131px;
}
.resource-avatar span img {
  height: 60px !important;
  width: 60px !important;
  object-fit: cover;
  object-position: top center;
  border-radius: 50px !important;
}
.virtual-scrolling .e-resource-column-wrap .e-resource-cells {
  vertical-align: middle !important;
}

.overlay-table-collapsed {
  border-collapse: collapse;
}
.overlay-striped-table tr:nth-child(even) {
  background-color: #f8f8fa;
}

.overlay-striped-table tr:nth-child(odd) {
  background-color: #ffffff;
}

.footerstyle {
  font-family: 'Inter bold';
  padding-left: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkboxSpacing {
  margin-right: 55px;
}

.lineItemstyle {
  font-family: 'Inter bold';
  padding-right: 40px !important;
}

.footerSubHeaderStyle {
  padding-right: 35px;
}

.boldFontFamily {
  font-family: 'Inter bold';
}

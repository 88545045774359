.side-r {
  width: 50% !important;
  border: 1px solid #e9ecef !important;
}

.side-body {
  width: 100% !important;
  height: 456px !important;
  overflow: overlay !important;
}

.side-header {
  background-color: #e9ecef;
}

.side-l {
  width: 50% !important;
  border: 1px solid #e9ecef !important;
}

.hand-cursor {
  cursor: pointer;
}

.fix-width {
  min-width: 30px !important;
}

.inventory-panel .p-panel-title {
  min-height: 90px !important;
}

.inventory-panel .p-panel-header {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}

.textbox-other {
  width: 100px !important;
  margin-left: 2px !important;
}

.inventory-datatable .p-datatable-wrapper {
  height: 541px !important;
}

.inventory .p-calendar-w-btn-right .p-inputtext {
  max-width: 100% !important;
}

.p-datatable .p-rowgroup-header {
  background-color: var(--primary-50) !important;
}

h3 {
  margin-block-start: 0;
  margin-block-end: 0;
}

.width-1200 {
  min-width: 1200px;
  overflow: auto;
}

.width-200 {
  min-width: 200px;
}

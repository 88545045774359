:root {
  --color-white: #ffffff;
  --pink-shade-1: #c76daf;
  --black-transparent-3: rgba(0, 0, 0, 0.3);
  --black-transparent-1: rgba(0, 0, 0, 0.1);
  --black-transparent-08: rgba(0, 0, 0, 0.08);
  --black-transparent-02: rgba(0, 0, 0, 0.2);
  --color-gray-charcoal: #333333;
  --color-gray-slate: #444444;
  --color-gray-stone: #555555;
  --color-gray-stone-1: #666666;
  --color-gray-silver: #d3d3d3;
  --color-gray-silver-1: #f5f5f5;
  --color-gray-aluminum: #afafaf;
  --color-blue-sky: #7bdbff;
  --color-blue-ocean: #67bbf5;
  --color-blue-powder: #b7e1ff;
  --color-blue-royal: #0066ff;
  --blue-shade-5: #0062b9;
}

.fc-toolbar-chunk {
  display: contents;
  align-items: center;
}
.fc .fc-datagrid-cell-cushion {
  padding: 0px !important;
  width: 100%;
}
.fc-toolbar-title {
  height: 17px;
  color: #171f28;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 17px;
  width: 20%;
  text-align: center;
  font-family: 'Inter bold';
}
.fc .fc-timeline-slot-cushion {
  padding: 4px 15px !important;
}
.fc-timeline-event {
  padding: 5px !important;
  height: 100%;
}
.fc-h-event {
  border: none !important;
}
.fc-timeline-event:not(.fc-event-start):before,
.fc-timeline-event:not(.fc-event-end):after,
.fc-resetButton-button {
  display: none !important;
}

.fc-prevButton-button,
.fc-nextButton-button {
  background: none !important;
  border: 0.5px solid #b3b3b3 !important;
  width: 28px;
  height: 28px;
  border-radius: 50% !important;
  padding: 0;
  color: black !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin-left: 0px !important;
}
.fc-icon {
  font-size: 1em !important;
}
.fc .fc-resource-timeline-divider {
  width: 0px !important;
  cursor: none !important;
}
.fc .fc-toolbar.fc-header-toolbar {
  padding: 0 !important;
  margin-bottom: 21px !important;
}
.fc-resetButton-button,
.fc-today-button,
.fc-fullScreenButton-button,
.fc-refreshButton-button {
  box-sizing: border-box !important;
  border: 0.5px solid var(--color-white) !important;
  background-color: var(--color-white) !important;
  color: #0062b8 !important;
  text-align: center !important;
  margin-left: 3px !important;
  font-size: 1em !important;
  font-family: 'Inter bold' !important;
}
.fc-resourceTimelineDay-button,
.fc-resourceTimelineWeek-button,
.fc-resourceTimelineMonth-button {
  width: 64px !important;
  border: none !important;
  border-radius: 0px !important;
  background-color: #fbfbfb !important;
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 20px;
  text-align: center !important;
  padding-bottom: 13px !important;
  color: #212b36 !important;
  font-family: Inter !important;
  font-size: 13px !important;
  letter-spacing: 0 !important;
  line-height: 16px !important;
  border-bottom: 3px solid var(--color-white) !important;
}
.fc-button-active {
  border-bottom: 3px solid #0074d9 !important;
  font-weight: bold !important;
}
.fc .fc-button-primary:focus {
  box-shadow: none !important;
}
/***Custom Styles for scheduler card ****/
.schedule-job-card {
  background-color: var(--white-shade-75);
  border: 1px solid var(--black-transparent-08);
  padding: 10px;
  border-radius: 6px;
  max-height: 90px !important;
  min-height: 90px !important;
  font-family: 'Lato', sans-serif;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px var(--black-transparent-1);
}
.minWidth-600 {
  min-width: 500px;
}
.card-heading.card-heading-time {
  font-size: 0.7rem;
  color: var(--pink-shade-1);
}
.schedule-job-card:hover,
.vertical-card:hover {
  overflow-y: auto !important; /* Show scroll on hover */
}

.schedule-job-card::-webkit-scrollbar,
.vertical-card::-webkit-scrollbar {
  opacity: 0;
  width: 8px;
  height: 8px;
}

.schedule-job-card::-webkit-scrollbar-thumb,
.vertical-card::-webkit-scrollbar-thumb {
  background-color: var(--black-transparent-3);
  border-radius: 4px;
  opacity: 1;
}

.schedule-job-card::-webkit-scrollbar-track {
  background-color: transparent;
}

.card-heading {
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem;
  text-align: center;
  color: var(--color-gray-charcoal);
  font-weight: bold;
  margin-bottom: 8px;
}

.section-heading {
  font-family: 'Lato';
  letter-spacing: 1.1px;
  font-weight: 700;
  padding-bottom: 4px;
  border-top: 1px solid var(--color-gray-silver);
  padding-top: 5px;
}

.border-bottom-dashed {
  border-bottom: 1px dashed var(--black-transparent-02);
}

.schedule-job-heading {
  font-size: 11px;
  font-weight: 600;
  color: var(--color-gray-slate);
  margin-bottom: 2px;
}

.schedule-job-card p {
  font-size: 11px;
  line-height: 1.1;
  margin: 0;
  color: var(--color-gray-stone);
}
.schedule-job-subheading {
  font-size: 11px;
}

.fc-timeline-more-link {
  background-color: var(--color-gray-silver-1);
  font-family: 'Inter bold';
  letter-spacing: 1px;
  text-indent: 5px;
}

/* Resource Technicial Card Style */

.resource-container {
  position: relative;
  display: inline-block;
}

.resource-container .card {
  background: var(--color-white);
  box-shadow: 0 4px 8px var(--black-transparent-1);
  display: flex;
  min-height: 118px;
  margin-bottom: 12px;
  overflow: hidden;
  border: none;
  position: relative;
  z-index: 1;
}

.resource-container .name {
  font-size: 16px;
  color: var(--blue-shade-5);
  font-family: 'Inter bold';
  border-bottom: 1px solid var(--color-gray-aluminum);
}

.resource-container .detail {
  font-size: 12px;
  color: var(--color-gray-stone-1);
  line-height: 1.3;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.resource-container .detail svg {
  margin-right: 5px;
}

.resource-container .detail a {
  text-decoration: none;
  color: var(--color-blue-royal);
}

/* Yellow Border */
.resource-container .border-yellow {
  position: absolute;
  top: 6px;
  left: 5px;
  z-index: 101;
  width: 7%;
  height: 23%;
  border-top: 5px solid var(--color-blue-powder);
  border-left: 5px solid var(--color-blue-ocean);
}

/* Blue Border */
.resource-container .border-blue {
  position: absolute;
  top: 14px;
  left: 14px;
  z-index: 100;
  width: 8%;
  height: 25%;
  border-top: 3px solid var(--color-gray-silver);
  border-left: 3px solid var(--color-blue-sky);
}
.resource-container .avatar img {
  object-fit: cover;
}
.minWidth-500 {
  min-width: 500px;
}
.minWidth-600 {
  min-width: 600px;
}
.minWidth-130 {
  min-width: 130px;
}
.fc-direction-ltr .fc-timeline-more-link {
  margin-right: 1px;
  color: var(--lightgrey);
  background-color: var(--primary-color);
}
.fc-more-popover {
  max-width: 360px;
  max-height: 300px;
  overflow: auto;
}
.scheduler-page .e-resource-column-wrap .e-resource-cells,
.calendar-component .e-resource-column-wrap .e-resource-cells {
  vertical-align: top;
}
.calendar-full-height .e-day-view .e-appointment-details .schedule-job-card,
.calendar-page-height .e-day-view .e-appointment-details .schedule-job-card {
  height: 100% !important;
  min-height: 100% !important;
  /* max-height: 100% !important; */
}

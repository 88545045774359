.skeleton-class {
  min-height: 126px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.excel-export-container {
  display: flex;
  justify-content: space-between;
  height: 0;
}
.excel-export-wrapper {
  position: absolute;
  right: 20px;
  top: 10px;
  height: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-items: center;
}

.chart-loader {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  cursor: pointer;
  background: none;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.info-message {
  font-size: small;
  font-weight: 700;
}
.view-rotation-percentage-icon {
  cursor: pointer;
  font-size: 1rem;
}

.type-wrapper {
  display: flex;
  gap: 25px;
  align-items: baseline;
}

.copy-rotation-container {
  display: flex;
  gap: 20px;
}

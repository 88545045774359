.crm .p-card-body {
  padding: 10px !important;
}

.crm .p-card-content {
  padding: 0px !important;
}

.crm .surface-border {
  border: 1px solid #ced4da !important;
}

.crm .p-dialog-header-close {
  background: white !important;
}

.crm .p-disabled,
.p-component:disabled {
  opacity: 1;
}

.quote-items .p-dropdown-panel {
  width: 250px !important;
}

.item-static-table tr:nth-child(even) {
  background-color: #f8f8fa !important;
}

.item-static-table {
  border-collapse: collapse !important;
  border-spacing: 0 !important;
}

.col-width-100 {
  width: 100px !important;
}

.col-width-150 {
  width: 150px !important;
}
.col-width-200 {
  width: 200px !important;
}

/* MUI custom Css */
h2.MuiTypography-h2,
.p-dialog-title {
  font-size: 21px;
  color: var(--heading-color);
  font-family: 'Inter bold';
}
h4 {
  font-size: 16px;
  color: var(--heading-color);
}
h6.MuiTypography-h6,
h3.MuiTypography-h3 {
  color: var(--heading-color);
  font-family: 'Inter bold';
}
h3.MuiTypography-h3 {
  font-size: 18px;
}
.p-inputgroup #max_travel_distance input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0;
}
.p-inputgroup #distance_unit {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
.MuiTab-root {
  padding: 17.5px 17.5px 15.5px !important;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}
div.MuiTabs-root {
  border-bottom: 2px solid #dee2e6;
}
div.MuiTabs-root svg,
.project-info svg {
  fill: #212121 !important;
}
.MuiTabs-root .Mui-selected {
  background-color: var(--primary-color);
}
.MuiTabs-root .Mui-selected span {
  color: #ffffff;
  font-family: 'Inter bold';
}
.MuiTabs-root span {
  color: #609af8;
  font-weight: 400;
  font-family: 'Inter';
  font-size: 14px;
}
span.MuiTabs-indicator {
  display: none;
}
table .MuiTableCell-head {
  padding-top: 8px;
  padding-bottom: 8px;
}
table .MuiTableCell-head .MuiButton-label,
table .MuiTableCell-head {
  font-family: 'Inter bold';
  font-weight: 400;
  font-size: 14px;
}
table .MuiTableCell-head button {
  margin-left: 0;
}
.MuiToolbar-root {
  border-bottom: 1px solid #e0e0e0;
}
.MuiCheckbox-root .MuiIconButton-label .MuiSvgIcon-root {
  fill: #0074d9 !important;
}
.MuiTable-root th {
  background: #eff6ff;
  padding: 14px;
  white-space: nowrap;
  font-size: 14px;
}
.MuiTableCell-root {
  border-top: none !important;
}
.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: #d0e1fd;
  color: #0074d9;
}
.MuiTabPanel-root,
.MuiTabPanel-root .MuiPaper-root {
  background-color: #ffffff;
}
button.MuiButton-root {
  font-size: 0.875rem;
  padding: 0.65625rem 1.09375rem;
  border-radius: 6px;
  font-family: 'inter';
  line-height: 1.22;
  font-weight: 400;
  border-width: 1px;
  border-style: solid;
  border-color: #0074d9;
}
.mui-remove-dashboard button,
.mui-remove-dashboard button:hover {
  border-color: #ef4444 !important;
  background-color: transparent !important;
  color: #ef4444;
}
.mui-remove-dashboard button svg {
  fill: #f50057 !important;
}
.mui-add-dashboard button,
.mui-remove-dashboard button:hover {
  background-color: transparent !important;
}
.mui-add-dashboard button svg {
  fill: #0074d9 !important;
}
table th.MuiTableCell-head button {
  border-width: 0;
  padding: 0;
  line-height: inherit;
}
button.MuiButtonBase-root.Mui-disabled {
  opacity: 0.6 !important;
  background: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  color: #ffffff !important;
}
.mui-custom-form .MuiButtonBase-root.Mui-disabled {
  opacity: 0.6 !important;
  background: transparent !important;
  border: none !important;
  color: #ffffff !important;
}
.mui-custom-autocomplete .MuiButtonBase-root.Mui-disabled {
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
  border-radius: 0;
  background-color: transparent !important;
}
.MuiTablePagination-root button.MuiButtonBase-root,
.MuiTabs-root .MuiButtonBase-root.MuiTabs-scrollButtons,
.project-info .MuiButtonBase-root.Mui-disabled,
.MuiAutocomplete-endAdornment button.MuiButtonBase-root.Mui-disabled {
  background: transparent !important;
  border: none !important;
}
.project-header button.MuiButton-root {
  padding: 3px;
  border-radius: 0px;
  border: 1px solid #e7d497;
}
button.MuiButton-contained:hover {
  box-shadow: none;
}
.table .MuiTableCell-head button {
  border-width: 0;
}
button.MuiButton-root .MuiButton-label {
  display: flex;
}
table button.MuiIconButton-root:hover {
  background-color: transparent;
}
table button.MuiIconButton-root .MuiIconButton-label .MuiSvgIcon-root {
  fill: #212121;
}
table.MuiTable-root tbody tr {
  border-bottom: 1px solid #e5e7eb;
}
table.MuiTable-root tbody tr div:first-child {
  min-height: 30px;
  display: flex;
  align-items: center;
}
.MuiDialog-container h6.MuiTypography-h6 {
  font-family: 'inter';
}
.calendar-head button {
  margin-left: 5px;
  margin-right: 5px;
}
.calendar-head button .MuiButton-label {
  color: #004e93;
}
.calendar-head button.MuiButton-containedPrimary .MuiButton-label {
  color: #ffffff;
}
tr.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: #ffffff;
}
.MuiButtonBase-root.Mui-disabled.MuiButton-outlinedPrimary {
  opacity: 0.6 !important;
  background: #ffffff !important;
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
}
footer .MuiTabs-root {
  background-color: #eff6ff;
  border: 0;
}
footer .MuiTabs-root button {
  max-width: max-content;
}
footer .MuiTabs-root button svg {
  fill: var(--primary-color) !important;
}
.document-tab .MuiTypography-body2 {
  font-size: 12px;
  text-align: center;
}
.MuiPickersCalendarHeader-switchHeader button.MuiButtonBase-root.Mui-disabled {
  background-color: transparent !important;
  border: 1pc solid transparent !important;
}
.MuiTablePagination-root .MuiTablePagination-toolbar {
  border-bottom: none;
}
.fc-header-toolbar .fc-today-button,
.fc-header-toolbar .fc-resetButton-button,
.fc-header-toolbar .fc-refreshButton-button,
.fc-header-toolbar .fc-fullScreenButton-button {
  height: 40px;
  border-radius: 6px !important;
}
.fc-header-toolbar .fc-newEvent-button {
  height: 40px;
  border-radius: 6px !important;
  background-color: #0074d9 !important;
  border-color: #0074d9 !important;
}
.mui-field-icon button.MuiButton-root {
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
  border-radius: 0;
  background-color: transparent !important;
  text-align: center;
}
.mui-field-icon button.MuiButton-root .MuiButton-startIcon {
  margin: 0;
}
.mui-calendar button.MuiButtonBase-root.Mui-disabled {
  background: transparent !important;
  border: none !important;
}
.MuiPickersBasePicker-container .MuiToolbar-regular button.MuiButton-root,
.MuiPickersBasePicker-container
  .MuiToolbar-regular
  button.MuiButton-root:hover {
  padding: 0;
  background-color: transparent;
}
.MuiPickersBasePicker-container .MuiToolbar-regular button.MuiButton-root h3,
.MuiPickersBasePicker-container .MuiToolbar-regular h3 {
  color: #ffffff;
}
.MuiPickersBasePicker-container
  .MuiGrid-root.MuiGrid-wrap-xs-nowrap.MuiGrid-justify-content-xs-center,
.MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-justify-content-xs-center.MuiGrid-grid-xs-6 {
  justify-content: left !important;
}
.mui-subject input {
  padding: 10px 0 15px 12px;
}
.MuiMenu-list li.Mui-disabled span {
  color: var(--black) !important;
}

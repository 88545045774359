.document__editor__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  min-width: 100%;
  padding: 5px; /* Optional padding */
  border-bottom: 1px solid #ccc; /* Greyish border */
}

.document__editor__header__title {
  margin: 0px;
}
.document__editor__header__name {
  margin: 0px;
  color: #2f4f4f;
}

.document__editor__header__buttons {
  display: flex;
  align-items: center;
}
.document__editor__header__buttons > button {
  margin: 5px;
  height: 35px;
}
.MuiCheckbox-root.Mui-disabled .MuiSvgIcon-root {
  fill: #b3b3b3 !important;
}

.doc-height {
  height: 152px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.doc-template {
  height: 152px;
  padding-left: 6px;
}

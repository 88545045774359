.highlight {
  color: #5f5a05;
  animation: 0.75s highlight 0.75s 1 normal forwards;
  background-color: none;
  background: linear-gradient(90deg, #fbfaaa 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 220% 100%;
  background-position: 100% 0;
  padding: 5px;
  margin: -5px;
}
.highlightDelete {
  color: black;
  -webkit-animation: 0.75s highlight 0.75s 1 normal forwards;
  animation: 0.75s highlight 0.75s 1 normal forwards;
  color: #960202;
  background-color: none;
  background: linear-gradient(90deg, #ffc4c4 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 220% 100%;
  background-position: 100% 0;
  padding: 5px;
  margin: -5px;
}
@-webkit-keyframes highlight {
  to {
    background-position: 0 0;
  }
}

@keyframes highlight {
  to {
    background-position: 0 0;
  }
}
